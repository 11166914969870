/**
 * @author Sebastian Kokott, Iker Hurtado
 *
 * @overview File holding the OutputAims class
 */


import Output from './Output.js'
import Structure from '../common/Structure.js'
import {getParallelepipedVolume, lineArrayToText, geoTextFromStructure} from './util.js'
import * as util from '../common/util.js'
import * as UserMsgBox from "../common/UserMsgBox";

const floatRegex = /[-+]?[0-9]+[.][0-9]*([eE][-+]?[0-9]+)?/g


/**
 * FHI-aims code calculation output
 */
export default class OutputAims extends Output {

  constructor(){
    super()
    this.inputs = {'controlIn':[], 'geometryIn':[]}
    this.output = {'body':[], 'finale':[]}
    this.output = {'body':[], 'finale':[]}
    // this.filesInOutput = {
    //   'hasControl':false,
    //   'hasGeometry':false,
    //   'hasBody':false,
    // }
    this.noSoc = false
    this.isGW = false
    // Band structure
    this.segmentsMap = new Map()
    this.bandNames = []  //  {bandZero: [], bandOne: [], bandThree: []}
  }


  /**
   * Parses the all the calculation output files
   * @param  {Array<object>} filesData
   */

  async parseFiles(filesData) {
    // check for archives in the files; if there are, unpack them on the backend and continue as usual
    return new Promise(resolve => {
      if (filesData.length === 1 && filesData[0].type.includes('gzip'))
          prepareWorkflowOutputs(filesData[0]).then((data) => resolve(this.#parse(data, true)))
        else
          resolve(this.#parse(filesData, false))
    })
    async function prepareWorkflowOutputs(fileData) {
      const response = await fetch('/upload-workflow', {
        method: 'POST',
        body: fileData.content
      })
      if (!response.ok){
        UserMsgBox.setError('Unknown server ERROR ')
        return
      }
      const text = await response.text()
      return JSON.parse(text)
    }
  }

  /** Parses uploaded file data with respect to workflow
   *
   * @param filesData {Object[]} a content of the uploaded files
   * @param isWorkflow {boolean} a flag that is raised in the case of the workflow
   */
  #parse(filesData, isWorkflow=false) {
    const path = require("path");

    filesData.forEach( (fileData) => {  // For every file
      const fileName = fileData.name
      const isBandFile = isWorkflow ? fileName.includes('/band') : fileName.startsWith('band')
      const isGWBandFile = isWorkflow ? fileName.includes('/GW_band') : fileName.startsWith('GW_band')
      if (isBandFile && fileName.endsWith('.out'))
        this.bandNames.push(fileName)
      if (isBandFile && fileName.endsWith('.no_soc')) {
        this.bandNames.push(fileName)
        this.noSoc = true
      }
      if (isGWBandFile && fileName.endsWith('.out')){
        this.isGW = true
        this.bandNames.push(fileName)
      }
    })
    console.log(this.bandNames + ': no SOC - ' + this.noSoc + ', is GW - ' + this.isGW)

    filesData.forEach( (fileData) => { // For every file
      const fileName = fileData.name
      // work with workflows
      let workflowIdx = undefined
      if (isWorkflow) {
        let workflow = path.dirname(fileName)
        if (!this.workflows.includes(workflow))
          this.workflows.push(workflow)
        workflowIdx = this.workflows.indexOf(workflow)
      }
      const isBandFile = isWorkflow ? fileName.includes('/band') : fileName.startsWith('band')
      const isGWBandFile = isWorkflow ? fileName.includes('/GW_band') : fileName.startsWith('GW_band')
      let lines = util.getTokenizedLines(fileData.content) // fileData.content.replace(/[ \t]+/g, ' ').split('\n');
      if (fileName.match(/KS_DOS_total(_tetrahedron)?.dat/g)
        || fileName.match(/_proj_dos(_tetrahedron)?\.dat(\.no_soc)?/g)
        || fileName.match(/atom_proj(ected)?_dos(_tetrahedron)?_[A-Z][a-z]?[0-9]{4}\.dat(\.no_soc)?/g)
      ){
        this.files.dos.set(fileName, getTabulatedDataFHIAims(lines))
        //console.log(this.files.dos)
        this.filledUp = true
      }else if (fileName.match(/_proj_dos(_tetrahedron)?_spin_(up|dn)\.dat(\.no_soc)?/g)){
        // Some more sorting is needed for species-projected DOS with spin.
        let altFileName = fileName.replace('_spin_up','').replace('_spin_dn','')
        if (this.files.dos.has(altFileName)){
          let data = this.files.dos.get(altFileName)
          let dataNew = getTabulatedDataFHIAims(lines)
          dataNew.forEach((line,i) => {
            if (fileName.includes('spin_up'))
              data[i][1] = line[1]
            else
              data[i][2] = line[1]
          })
        } else {
          let data = []
          getTabulatedDataFHIAims(lines).forEach(line => {
            if (fileName.includes('spin_up'))
              data.push([line[0],line[1],0.0])
            else 
              data.push([line[0],0.0,line[1]])
          })
          // console.log(data);
          this.files.dos.set(altFileName,data)
        }
        this.filledUp = true
        this.runTimeChoices.hasSpin = true
      } else if (fileName.match(/absorption(_soc)?(_Lorentzian)?(_Tetrahedron)?_[0-9].[0-9]{4}(_x_x)?(_y_y)?(_z_z)?.out/g)) {
        this.files.absorption.set(fileName, getTabulatedDataFHIAims(lines))
        // console.log('in if absorption')
        this.filledUp = true
      } else if (fileName.match(/dielectric_function(_soc)?(_Lorentzian)?(_Tetrahedron)?_[0-9].[0-9]{4}(_x_x)?(_y_y)?(_z_z)?(_x_y)?(_x_z)?(_y_z)?.out/g)) {
        this.files.dielectric.set(fileName, getTabulatedDataFHIAims(lines))
        // console.log('in if absorption')
        this.filledUp = true
      } else if ((isBandFile || isGWBandFile) && fileName.includes('.out')) {
        addBSFileData(fileName, this.segmentsMap, lines, isGWBandFile, workflowIdx)  //, this.bandName)
        this.filledUp = true
      } else if (fileName.includes('control.in')) {
        this.files.input.set(fileName, fileData.content)
        this.controlIn = lines
        // this.filledUp = true
      } else if (fileName.includes('geometry.in')) {
        this.files.input.set(fileName, fileData.content)
        // this.filledUp = true
      } else if (fileData.content.includes('Invoking FHI-aims ...')) {
        this.files.output.set(fileName, fileData.content)
        this.filledUp = true
      }
    })
    return this
    // inner method functions

    function getTabulatedDataFHIAims(lines){
      let data = []
      let l = 0
      lines.forEach( line => {
        if (!line.includes('#') && line.length > 1){
          let a = line.trim().split(' ')
          for (let i = 0; i < a.length; i++) a[i] = parseFloat(a[i])
          data.push(a)
        }
        l++
      })
      return data
    }

    /**
     *
     * @param fullFileName {string}
     * @param segmentsMap {Map<string, {}>}
     * @param lines
     * @param isGW {boolean}
     * @param index {number}
     */
    function addBSFileData(fullFileName, segmentsMap, lines, isGW, index=undefined){
      const path = require('path');
      let fileName = path.basename(fullFileName)
      let segmentNumInd = [5,8]
      let spinIndexInd = 4
      // console.log(isGW);
      if (isGW === true) {
        segmentNumInd = [8,11]
        spinIndexInd = 7
      }
      let segmentNum = fileName.substring(segmentNumInd[0],segmentNumInd[1])
      let spinIndex = 0
      if (index === undefined) {
        if (fileName.endsWith(".no_soc")) {
          spinIndex = parseInt(fileName.charAt(spinIndexInd))
        } else if (fileName.endsWith(".out")) {
          spinIndex = parseInt(fileName.charAt(spinIndexInd)) - 1
        }
      } else spinIndex = index
      let segmentData
      if (segmentsMap.has(segmentNum))
        segmentData = segmentsMap.get(segmentNum)
      else{
        segmentData = { segment_num: parseInt(segmentNum), band_k_points: [], band_energies: [[],[],[]], bsName: [[],[],[]], segmentName: [[],[],[]] }
        segmentsMap.set(segmentNum, segmentData)
      }
      let fileNameToken = fileName.substring(0,segmentNumInd[0])+'***'+fileName.substring(segmentNumInd[1])
      segmentData.bsName[spinIndex].push(fileNameToken)
      segmentData.segmentName[spinIndex].push(fileName)

      // fill band_k_points only once
      let k_point_flag = undefined
      if (segmentData.band_k_points.length === 0)
        k_point_flag = spinIndex
      lines.forEach( line => {
        if (line.length > 1){
          let a = line.trim().split(' ')
          for (let i = 0; i < a.length; i++) a[i] = parseFloat(a[i])
          if (spinIndex === k_point_flag) segmentData.band_k_points.push(a.slice(1,4))
          segmentData.band_energies[spinIndex].push(a.slice(4).filter( (e, i) => {return i%2 === 1} ))
          // For the future:a.slice(4).filter( (e, i) => {return i%2 === 0} ) occupation number and is the number of electrons occupying the corresponding state.
        }
      })
    }

  } // END getOutputFromFiles()


  /**
   * Returns an object with all the band structure info to plot it
   * @return {object}
   */
  getBsInfo(){
    if (!this.bsInfo){
      if (this.controlIn)
        this.bsInfo = this.findLackingSegments(this.controlIn, this.segmentsMap)
      else
        this.bsInfo = this.findLackingSegments(undefined, this.segmentsMap)
    }
    this.bsInfo.hasSpin = (this.runTimeChoices.spin === "collinear")
    this.bsInfo.hasSOC = this.runTimeChoices.hasSOC
    return this.bsInfo
  }
  /**
   * Returns an object with all the band structure info to plot it
   * @return {object}
   */
  // bandNamesCarrier(){
  //   return this.bandNames
  // }

  /**
   * Parses the main calculation output file.
   * If fileName is not passed in it, looks for the first one
   * @param  {string} fileName
   */
  parseOutputFile(fileName){
    if (this.files.output.size === 0) return

    let controlInLines = this.controlIn // It can be undefined

    if (!fileName) fileName = this.files.output.keys().next().value

    //this.parseFile(fileName)
    const fileText = this.files.output.get(fileName)
    this.parseFile(fileName, fileText)

    if (!controlInLines) controlInLines = this.inputs.controlIn
    // console.log('controlInLines',controlInLines);
    // Postprocessing the bs data
    this.bsInfo = this.findLackingSegments(controlInLines, this.segmentsMap)
  }


  /**
   * Finds out the band structure lacking segments
   * @param  {Array<string>} controlIn
   * @param  {Map<string, object>} segmentsMap
   * @return {object}
   */
  findLackingSegments(controlIn, segmentsMap){
      let lackingSegments = []  // array storing the lacking segment files
      const bsData = []
      // The segments labels are added to the BS segment info
      if (controlIn)
        lackingSegments = addSegmentLabelsToBS(controlIn, segmentsMap)

      // The segment info is sorted and stored by the segment number (coded in file name)
      let segmentNums = [...segmentsMap.keys()].sort()
      segmentNums.forEach( segmentNum => bsData.push(segmentsMap.get(segmentNum)) )

      // Try to find missing files if there wasn't control.in info
      if (lackingSegments.length === 0) {
        let maxNum = parseInt(segmentNums[segmentNums.length-1])
        for (let i = 1; i < maxNum; i++) {
          const code = getFileCodeFromNumber(i)
          if (!segmentNums.includes(code)) lackingSegments.push(code)
        }
      }
      return { lackingSegments: lackingSegments, bsData: bsData}

    // inner functions

    function addSegmentLabelsToBS(lines, segmentsMap){
      let segmentsLabels = []
      let lackingSegments = []
      lines.forEach( line => {
        let a = line.trim().replace(/[ \t]+/g, ' ').split(' ')
        if (a[0] === 'output' && a[1] === 'band')
          segmentsLabels.push(a.slice(-2))
      }) //log('segmentsLabels', segmentsLabels)

      for (let i = 0; i < segmentsLabels.length; i++) {
        let key = getFileCodeFromNumber(i+1)
        if (segmentsMap.has(key))
          segmentsMap.get(key).band_segm_labels = segmentsLabels[i]
        else
          lackingSegments.push(key)
      }
      return lackingSegments
    }

    function getFileCodeFromNumber(n){
        return (n < 10 ? '00'+n : '0'+n)
    }
  }


  /**
   * Parses the main calculation output file.
   * @param  {string} fileName
   * @param  {string} fileText
   */
  parseFile(fileName, fileText){
    // this.parseFileAndInit(fileName, fileText)
    // this.getRunTimeChoices()
    this.normalParser(fileText)
    this.ksEv = this.getKsEv(fileText)
    // console.log(this.errors);
    this.getDataSeries()
    if (this.runTimeChoices.calculationType === 'relaxation') {
      this.getRelaxationSeries()
    }
    // this.grepResults()
  }


  /**
   * Gathers and stores the data series for the convergence graph
   */
  getDataSeries() {
    // convergence accuracy
    let dataSeries = []

    this.scfLoops.forEach(loop => {
      // console.log(loop.iterations);
      // if (loop.iterations) console.log(loop.iteration);
      let hasIter = loop.iterations ? loop.iterations.length > 0 : false
      // console.log('hasIter',hasIter);
      if (hasIter) {
        let dataSeriesIteration = {
          'eigenvalues': {'label': 'Change of Eigenvalues', 'color':'rgb(0,157,220)', 'data':[]},
          'totalEnergy': {'label': 'Change of Total Energy', 'color':'rgb(242,100,48)', 'data':[]},
        }
        if (this.runTimeChoices.spin === 'collinear') {
          dataSeriesIteration['chargeDensityUp'] = {'label': 'Change of Total Density', 'color':'rgb(42,45,52)', 'data':[]}
          dataSeriesIteration['chargeDensityDown'] = {'label': 'Change of Spin Density', 'color':'rgb(84,45,52)', 'data':[]}
        } else {
          dataSeriesIteration['chargeDensity'] = {'label': 'Change of Density', 'color':'rgb(42,45,52)', 'data':[]}
        }

        loop.iterations.forEach(iteration => {
          for (let [key,entry] of Object.entries(dataSeriesIteration)) {
            // console.log(key, entry);
            entry['data'].push(iteration.convergenceAccuracy[key])
          }
        })
        dataSeries.push(dataSeriesIteration)
      }
    })
    // console.log(dataSeries);
    this.dataSeries = dataSeries
  }


  /**
   * Gathers and stores the data series for the relaxation graph
   */
  getRelaxationSeries() {
    let iters = [], dataI = [], forceI = [], dEnd = 0.0
    let i = 0
    this.scfLoops.forEach(loop => {
      i += 1
      if (loop.isConverged) {
        iters.push(i)
        dEnd = loop['finalScfEnergies']['totalEnergy']
        dataI.push(loop['finalScfEnergies']['totalEnergy'])
        forceI.push(loop['maxForceComponent'])
      }
    })
    this.relaxationSeries = {
      'labels': iters,
      'energy': {
        "label": "Total Energy",
        "data": dataI.map(function(value){return Math.abs(value-dEnd)}),
        "yAxisID": 'yscenergy',
        "fill": false,
        "borderColor": "rgb(42,45,52)",
        "lineTension": 0.1},
      'forces': {
        "label": "Maximum Force Component",
        "data": forceI.map(Math.abs),
        "yAxisID": 'ymaxforce',
        "fill": false,
        "borderColor": "rgb(0,157,220)",
        "lineTension": 0.1}
    }
  }


  /**
   * Returns the structure got from the geometry input file
   * @return {Structure}
   */
  getStructure(){
    let geometryInText
    if (this.inputs.geometryIn.length > 0) {
      geometryInText = lineArrayToText(this.inputs.geometryIn)
    } else if (this.files.input.get('geometry.in')) {
      geometryInText = this.files.input.get('geometry.in')
    }
    // console.log(geometryInText);
    let structure
    if (geometryInText)
      structure = util.getStructureFromFileContent('geometry.in', geometryInText)
    // console.log(structure);
    return structure
  }


  /**
   * Returns the main quantities of the calculation results to be shown
   * @return {Map<string,object>}
   */
  getResultsQuantities(){
      const quantities = new Map()
      // find last converged scf loop
      let lastConverged = -1
      this.scfLoops.forEach(loop => {
        if (loop.isConverged) lastConverged += 1
      })
      if (lastConverged >= 0) {
        const lastLoop = this.scfLoops[lastConverged]
        // console.log(lastLoop);
        const lastIter = lastLoop['iterations'][lastLoop['iterations'].length -1]
        quantities.set('Total Energy (eV)', lastLoop.finalScfEnergies.totalEnergy)

        for (const entry of Object.values(lastIter['electronInfo'])) {
          quantities.set(entry.info, entry.value)
        }

        if (this.runTimeChoices.isPeriodic) {
          quantities.set('Cell Volume (&#197;<sup>3</sup>)',
            getParallelepipedVolume(this.structureIn.latVectors))
        }

        if (this.runTimeChoices.calculationType === 'relaxation') {
          const finalStructureText = geoTextFromStructure(lastLoop.structure)
          let href = window.URL.createObjectURL(new Blob([finalStructureText], {type: 'text/plain'} ))
          quantities.set('download-link', href)
        }
      }
      return quantities
  }


  /**
   * Returns summary calculation information
   * @return {Map<string,object>}
   */
  getCalculationInfo(){
    const quantities = new Map()
    // console.log(this.calculationInfo, this.finalTimings, this.memory, this.exitMode);
    let infoObjects = [this.calculationInfo, this.finalTimings, this.memory, this.exitMode]
    infoObjects.forEach( infoObject => {
      if (infoObject !== undefined) {
        for (const entry of Object.values(infoObject))
          quantities.set(entry.info, entry.value)
      }
    })
    return quantities
  }


  /**
   * Returns a map of the input files
   * @return {Map<string,string>}
   */
  getInputFilesMap(){
    const inputFilesMap = new Map()
    if (this.inputs.geometryIn.length > 0)
      inputFilesMap.set('geometry.in', lineArrayToText(this.inputs.geometryIn))
    if (this.inputs.controlIn.length > 0)
      inputFilesMap.set('control.in', lineArrayToText(this.inputs.controlIn))

    this.files.input.forEach( (content, name) => {
      if (!inputFilesMap.has(name)) inputFilesMap.set(name, content)
    })
    return inputFilesMap
  }


  /**
   * Gathers and stores the calculation runtime choices
   */
  getRunTimeChoices(){

    let rtc = this.runTimeChoices
    this.inputs.controlIn.forEach( line => {

      let trim_line = line.trim()

      if (trim_line.indexOf('relax_geometry') === 0 ){
        rtc.calculationType = 'relaxation'
        rtc.hasForces = true
      }
      if (trim_line.indexOf('spin') === 0 && trim_line.indexOf('collinear') > 0){
        rtc.spin = 'collinear'
      }
      if (trim_line.indexOf('output_level') === 0 && trim_line.indexOf('MD_light')> 0){
        rtc.outputLevel = 'MD_light'
      }
      if (trim_line.indexOf('k_grid') === 0){
        rtc.isPeriodic = true
      }
      if (trim_line.indexOf('relax_unit_cell') === 0){
        rtc.hasStress = true
      }
      if (trim_line.indexOf('include_spin_orbit') === 0){
        rtc.hasSOC = true
      }
      if (trim_line.indexOf('output') === 0 && trim_line.indexOf('hirshfeld') > 0) rtc.hasHirshfeld = true
      if (trim_line.indexOf('many_body_dispersion') === 0) rtc.hasMBD = true
      if (trim_line.indexOf('output') === 0 && trim_line.indexOf('mulliken') > 0){
        rtc.hasMulliken = true
      }

    })
  }


  /**
   * @param  {string} fileText
   */
  getKsEv(fileText){
    let ksEv = []
    let lines = fileText.split('\n')
    let foundKsEv = false
    let spinCollinear = false
    let lineLength = 0
    lines.forEach(line => {
      if (line.includes('spin') && line.includes('collinear')) {
        spinCollinear = true
      }
      if (line.includes("State") && line.includes("Occupation") && line.includes("Eigenvalue") && !spinCollinear) {
        ksEv = []
        foundKsEv = true
        lineLength = line.length
      }
      else if (spinCollinear && line.includes('Spin-up') && line.includes('eigenvalues:')) {
        ksEv = []
      }
      if (line.includes("State") && line.includes("Occupation") && line.includes("Eigenvalue") && spinCollinear) {
        foundKsEv = true
        lineLength = line.length
      }
      else if (foundKsEv && line.length > 0){
        let a = line.trim().split(' ').filter(Number)
        for (let i = 0; i < a.length; i++) a[i] = parseFloat(a[i])
        //console.log('ksEv')
        //console.log(a[3])
        if (a[3]){
          ksEv.push([a[0],a[1],a[3]])
        }
        else if (!a[3]){
          ksEv.push([a[0],0.000,a[2]])
        }
      }
      else if (foundKsEv && line.length === 0){
        foundKsEv = false
      }
    })
    return ksEv
  }


  /**
   * Parses the main output file
   * @param  {string} fileText
   */
  normalParser(fileText){

    function *linesIterator(lines) {
      for ( let i in lines) {yield lines[i]}
    }

    let parseUntil = (regExs, stopRe) => {
      let lines = []
      let line = lineIt.next()
      if (line === undefined) return undefined
      while (!line.done) {
        regExs.forEach( re => {
            if (line.value.match(re)) lines.push(line.value)
        })
        if (line.value.match(stopRe)) return lines
        line = lineIt.next()
      }
      this.errors.push(['Reached end of file before finding',stopRe.toString()])
      return undefined // Should only happen if we stop before
    }

    let waitFor = (waitRe) => {
      let line = lineIt.next()
      if (line === undefined) return undefined
      while (!line.done) {
        if (line.value.match(waitRe)) {
          return line
        }
        line = lineIt.next()
      }
      this.errors.push(['Did not find the following expression',waitRe.toString()])
      return undefined
    }

    let checkForUntil = (checkRe,stopRe) => {
      let line = lineIt.next()
      // console.log(line);
      while (!line.done) {
        if (line.value.match(checkRe)) return true
        else if (line.value.match(stopRe)) return false
        line = lineIt.next()
      }
      this.errors.push(['Reached end of file before finding', stopRe.toString()])
      return undefined
    }
    let matchNextLine = (regex) => {
      let line = lineIt.next()
      return line.value.match(regex)
    }

    let getLinesInBetween = (startRe, stopRe) => {
      let lines = []
      waitFor(startRe)
      let line = lineIt.next()
      if (line === undefined) return undefined
      while (!line.done) {
        if (line.value.match(stopRe)) return lines
        lines.push(line.value)
        line = lineIt.next()
      }
      this.errors.push(['Reached end of file before finding',stopRe])
      return lines // Should only happen if we stop before
    }

    function parseSCFEnergies() {

      let energyRegex = [
        [/Sum of eigenvalues/g,'sumEigenvalues'],
        [/XC energy correct/g, 'XCenergyCorrection'],
        [/XC potential correct/g, 'XCpotentialCorrection'],
        [/Free-atom electrostatic energy/g, 'freeAtomElStat'],
        [/Hartree energy correct/g, 'hartreeEnergyCorrection'],
        [/Entropy correct/g, 'entropyCorrection'],
        [/Total energy\s/g, 'totalEnergy'],
        [/Total energy, T\s/g, 'totalEnergyCorrected'],
      ]
      let scfEnergies = {}
      let line = waitFor(/Total energy components:/g)
      if (line === undefined) return undefined

      energyRegex.forEach(re => {
        line = waitFor(re[0])
        let reMatch
        if (line){
          let m = line.value.match(floatRegex)
          if (m && m.length === 2) {
            reMatch = m[1]
            scfEnergies[re[1]] = parseFloat(reMatch)
          }
        }
      })
      if (Object.keys(scfEnergies).length === 8)
        return {'scfEnergies': scfEnergies}
      else
        return undefined

    }
    function parseConvAcc(runTimeChoices) {
      let convAcc = {}
      let line = waitFor(/Self-consistency convergence accuracy:/g)
      if (line === undefined) return undefined
      line = waitFor(/\| Change of charge/g)
      if (runTimeChoices.spin === 'collinear') {
        convAcc['chargeDensityUp'] = parseFloat(line.value.match(floatRegex)[0])
        convAcc['chargeDensityDown'] = parseFloat(line.value.match(floatRegex)[1])
      } else {
        convAcc['chargeDensity'] = parseFloat(line.value.match(floatRegex))
      }
      line = waitFor(/\| Change of sum of eigenvalues/g)
      convAcc['eigenvalues'] = parseFloat(line.value.match(floatRegex))
      line = waitFor(/\| Change of total energy/g)
      convAcc['totalEnergy'] = parseFloat(line.value.match(floatRegex))
      return {'convergenceAccuracy': convAcc}
    }

    function getElectronInfo(runTimeChoices) {
      let electronInfo = {}
      if (runTimeChoices.outputLevel === 'normal') {
        let line = waitFor(/\| Chemical potential/g)
        if (line === undefined) return undefined
        electronInfo['fermiEnergy'] = {
          'value': parseFloat(line.value.match(floatRegex)),
          'info': 'Fermi Energy (eV)'
        }
      }

      let line = waitFor(/Highest occupied state|Reaching maximum number/g)
      if (line === undefined) return undefined
      if (line.value.includes('Reached maximum number')) return {}
      let state = parseFloat(line.value.match(floatRegex))
      line = waitFor(/\| Occupation number:/g)
      if (line === undefined) return undefined
      let occNumber = parseFloat(line.value.match(floatRegex))
      electronInfo['highestOccState'] = {
        'value': state,
        'info': 'Highest occupied state (eV)',
        'occNumber':occNumber
      }

      line = waitFor(/Lowest unoccupied state/g)
      if (line === undefined) return undefined
      state = parseFloat(line.value.match(floatRegex))
      line = waitFor(/\| Occupation number:/g)
      occNumber = parseFloat(line.value.match(floatRegex))
      electronInfo['lowestUnOccState'] = {
        'value': state,
        'info': 'Lowest unoccupied state (eV)',
        'occNumber': occNumber
      }

      line = waitFor(/verall HOMO-LUMO gap:/g)
      if (line === undefined) return undefined
      let gap = parseFloat(line.value.match(floatRegex))
      electronInfo['gap'] = {
        'value': gap,
        'info': 'Estimated HOMO-LUMO gap (eV)',
      }
      return {'electronInfo': electronInfo}
    }

    function getIteration(runTimeChoices) {
      let iteration = {}
      let isConverged = false
      let line = waitFor('Begin self-consistency iteration|SELF-CONSISTENCY CYCLE DID NOT CONVERGE')
      if (line === undefined) return [undefined, undefined]
      if (line.value.indexOf('DID NOT CONVERGE') > 0) return [false, undefined]
      if (line.value) {
        let electronInfo = getElectronInfo(runTimeChoices)
        let scfEnergies = parseSCFEnergies()
        let convAcc = parseConvAcc(runTimeChoices)
        if (electronInfo === undefined || scfEnergies === undefined || convAcc === undefined) {
          iteration = undefined
        } else {
          iteration = {...scfEnergies,...convAcc,...electronInfo}
        }
        isConverged = checkForUntil('Self-consistency cycle converged','End self-consistency iteration')
      }
      return [isConverged, iteration]
    }

    function getScfCycle (runTimeChoices) {
      let scfCycle = {
        'structure': currentGeometry, //Object.assign({},currentGeometry),
        'iterations': [],
        'isConverged': false,
      }

      let isConverged = false
      let iteration = {}
      while(!isConverged && isConverged === false) {
        [isConverged, iteration] = getIteration(runTimeChoices)
        if (iteration) scfCycle['iterations'].push(iteration)
        else return scfCycle
      }
      scfCycle['isConverged'] = isConverged === undefined ? false : isConverged
      return scfCycle
    }

    function getScfCycleMDLight(runTimeChoices) {
      let scfCycle = {
        'structure': currentGeometry, //Object.assign({},currentGeometry),
        'iterations': [],
        'isConverged': false,
      }
      let isEnd = false
      let iter = -1
      let line = waitFor(/Convergence:/g)
      if (line === undefined) return [undefined, undefined]
      while(!isEnd) {
        // console.log("Calling getIteration");
        let m = matchNextLine(floatRegex)
        // console.log(m);
        if (m) {
          let iteration = {}
          if (runTimeChoices.spin === 'collinear' && m.length>=5) {
            iteration['convergenceAccuracy'] = {
              'chargeDensityUp':m[1],
              'chargeDensityDown':m[2],
              'eigenvalues':m[3],
              'totalEnergy':m[4],
            }
            scfCycle.iterations.push(iteration)
            iter += 1
          } else if (runTimeChoices.spin === 'none' && m.length>=4) {
            // console.log(m,m.length);
            iteration['convergenceAccuracy'] = {
              'chargeDensity':m[1],
              'eigenvalues':m[2],
              'totalEnergy':m[3],
            }
            scfCycle.iterations.push(iteration)
            iter += 1
          } else
          isEnd = true // Something went wrong
        } else
          isEnd = true
      }
      let scfEnergies = parseSCFEnergies()
       scfCycle.iterations[iter]['scfEnergies'] = scfEnergies['scfEnergies']
      let electronInfo = getElectronInfo(runTimeChoices)
      scfCycle.iterations[iter]['electronInfo'] = electronInfo['electronInfo']
      let isConverged = checkForUntil('Self-consistency cycle converged',
        'End self-consistency iteration|Reaching maximum number of scf iterations')
      scfCycle['isConverged'] = isConverged === undefined ? false : isConverged
      return scfCycle
    }

    function getGeoFromLines(geoLines) {
      let geo = new Structure()
      let hasCartesian = false
      // console.log(geoLines.length);
      geoLines.forEach(line => {
        // console.log(line)
        if (line.match(/^[\s\t]*atom[\s\t]+/g)) {
          let [_, __, x, y, z, species] = line.split(/[\s\t]+/)
          geo.addAtomData([parseFloat(x), parseFloat(y), parseFloat(z)], species.trim())
          hasCartesian = true
        } else if (line.match(/^[\s\t]*atom_frac[\s\t]+/g) && !hasCartesian) {
          let [_, __, x, y, z, species] = line.split(/[\s\t]+/);
          geo.addAtomData([parseFloat(x), parseFloat(y), parseFloat(z)], species.trim(), true)
        } else if (line.match(/^[\s\t]*lattice_vector[\s\t]+/g)) {
          if (geo.latVectors === undefined) geo.latVectors = []
          let [_, __, x, y, z] = line.split(/[\s\t]+/)
          geo.latVectors.push([parseFloat(x), parseFloat(y), parseFloat(z)])
        }
      })
      // console.log(geo);
      return geo
    }

    let getInitialGeometry = () => {

      let geoLines = this.inputs.geometryIn
      let geo = getGeoFromLines(geoLines)
      this.systemInformation.nAtoms = geo.atoms.length
      this.systemInformation.formulaUnit = getFormulaUnit(geo)
      return geo
    }

    let getFormulaUnit = (structure) => {
      let species = {}
      structure.atoms.forEach(atom => {
        let sp = atom.species
        species[sp] === undefined ? species[sp] = 1 : ++species[sp]
      })
      let formulaUnit = '' // formula unit
      for (let key in species) {
        let num = species[key]
        formulaUnit += (num === 1 ? key : key+num.toString())
      }
      return formulaUnit
    }

    let parseUpdatedGeo = () => {
      const oldGeo = currentGeometry
      let line = waitFor(/Geometry optimization: Attempting to predict improved coordinates\./g)
      if (line === undefined) return undefined
      line = waitFor(/Maximum force component/g)
      const maxForceComponent = parseFloat(line.value.match(floatRegex))

      line = waitFor(/Present geometry is/g)
      if (line.value.includes('not yet converged.')) {
        line = waitFor(/Updated atomic structure:/g)
        if (line === undefined) return undefined
        let geoLines = parseUntil([/atom\s+/g,/lattice_vector\s+/g],/---------/g)
        // console.log(geoLines);
        return {
          force: maxForceComponent,
          geo: getGeoFromLines(geoLines)
        }
      }else{
        line = waitFor(/Final atomic structure:/g)
        if (line === undefined) return undefined
        // line = lineIt.next()
        // this.finalGeoLines = parseUntil([/atom\s+/g,/lattice_vector\s+/g],/---------/g)
        return {
          force: maxForceComponent,
          geo: oldGeo
        }
      }
    }

    function getMullikenPop(soc=false) {
      let line
      let mullikenCharge = {}
      if (soc) {
        line = waitFor(/Performing scalar-relativistic Mulliken charge analysis/g)
        if (line === undefined) return undefined
        mullikenCharge['sr'] = readMulliken()
        line = waitFor(/Performing spin-orbit-coupled Mulliken charge analysis/g)
        if (line === undefined) return undefined
        mullikenCharge['soc'] = readMulliken()
      } else {
        line = waitFor(/Performing Mulliken charge analysis on all atoms/g)
        if (line === undefined) return undefined
        mullikenCharge['simple'] = readMulliken()
      }
      return mullikenCharge
    }

    let readMulliken = () => {
      let charge = []
      waitFor(/\| *atom/g)
      let line = lineIt.next()
      // read next nAtoms lines
      for (let i = 0; i < this.systemInformation.nAtoms; i++) {
        let match = line.value.match(floatRegex)
        charge.push(parseFloat(match[1]))
        line = lineIt.next()
      }
      return charge
    }

    let getHirshfeldPop = () => {
      let hirshfeldCharge = []
      waitFor(/Performing Hirshfeld analysis/g)
      for (let i = 0; i < this.systemInformation.nAtoms; i++) {
        let line = waitFor(/Hirshfeld charge/g)
        if (line === undefined) return undefined
        hirshfeldCharge.push(parseFloat(line.value.match(floatRegex)))
      }
      return hirshfeldCharge
    }

    let getFinalScfEnergiesForces = () => {
      let finalEnergies = {}
      let forces = []
      let maxForceComponent = undefined
      let line = waitFor(/Energy and forces in a compact form/g)
      if (line === undefined) return undefined
      line = lineIt.next()

      finalEnergies['totalEnergy'] = parseFloat(line.value.match(floatRegex))
      line = lineIt.next()
      finalEnergies['totalEnergyCorrected'] = parseFloat(line.value.match(floatRegex))
      line = lineIt.next()
      finalEnergies['electronicFreeEnergy'] = parseFloat(line.value.match(floatRegex))
      // console.log(this.runTimeChoices.hasForces);
      // console.log(line);
      if (this.runTimeChoices.hasForces) {
        line = waitFor(/Total atomic forces/g)
        // console.log(line);
        // console.log(this.systemInformation.nAtoms);
        for (let i = 0; i < this.systemInformation.nAtoms; i++) {
          line = lineIt.next()
          // console.log(line);
          if (line === undefined) return undefined
          let force = line.value.match(floatRegex).map(Number)
          forces.push(force)
        }
        maxForceComponent = getMaxForceComponent(forces)
      }
      return {
        'finalScfEnergies':finalEnergies,
        'forces':forces,
        'maxForceComponent': maxForceComponent,
      }
    }

    function getMaxForceComponent(forces) {
      let maxForceComponent = 0.0
      forces.forEach(force => {
        let absForces = force.map(Math.abs)
        // console.log(absForces);
        maxForceComponent = Math.max(...absForces, maxForceComponent)
      })
      return maxForceComponent
    }

    let getScfLoops = () => {
      let scfLoops = []
      let isLeaving = false
      let isStarting = false
      while(!isLeaving) {
        isStarting = checkForUntil('Begin self-consistency loop','Leaving FHI-aims')
        if (isStarting) {
          let scfCycle = {isConverged: false}
          if (this.runTimeChoices.outputLevel === 'normal') {
            scfCycle = getScfCycle(this.runTimeChoices)
          } else if (this.runTimeChoices.outputLevel === 'MD_light') {
            scfCycle = getScfCycleMDLight(this.runTimeChoices)
          }
          // Many-body dispersion implies Hirshfeld analysis
          // if (this.runTimeChoices.hasMBD) this.hirshfeld = getHirshfeldPop()
          let finalScfEnergiesForces = {}
          if (scfCycle.isConverged) finalScfEnergiesForces = getFinalScfEnergiesForces()
          scfLoops.push({...finalScfEnergiesForces,...scfCycle})
          if (this.runTimeChoices.calculationType === 'relaxation') {
            const geoObj = parseUpdatedGeo()
            currentGeometry = geoObj.geo
            scfLoops.at(-1).maxForceComponent = geoObj.force
          }
          if (this.runTimeChoices.hasSOC) {
            // first Hirshfeld, then Mulliken (SR+SOC)
            if (this.runTimeChoices.hasHirshfeld) this.hirshfeld = getHirshfeldPop()
            if (this.runTimeChoices.hasMulliken) this.mulliken = getMullikenPop(true)
          } else {
            if (this.runTimeChoices.hasMulliken) this.mulliken = getMullikenPop()
            if (this.runTimeChoices.hasHirshfeld) this.hirshfeld = getHirshfeldPop()
          }
        } else if (isStarting === undefined) {
          return scfLoops
        }
        else isLeaving = true
      }
      return scfLoops
    }

    let getInput = (start,stop) => {
      let cLines = getLinesInBetween(start,stop)
      cLines.splice(0,5) // remove the first six lines
      cLines.pop() // remove the 2 last lines
      cLines.pop()
      // console.log(cLines)
      return cLines
    }

    let getCalculationInfo = () => {
      let calculationInfo = {}
      let calculationInfoRegExs = [
        ['codeVersion','Code Version',/FHI-aims version\s+:\s+([0-9]+)/],
        ['commitNumber','Commit Number',/Commit number\s+:\s+([0-9,a-z]*)/],
        ['numberOfTasks','Number of Tasks',/Using\s+([0-9]*)\s+parallel tasks\./],
      ]
      let line = lineIt.next()
      while (!line.done) {
        if (line.value.match('Obtaining array dimensions for all initial allocations:'))
          return calculationInfo
        calculationInfoRegExs.forEach(re => {
          let m = line.value.match(re[2])
          if (m) calculationInfo[re[0]] = {'value': m[1],'info': re[1]}
        })
        line = lineIt.next()
      }
      return undefined
    }

    let getFinalTimings = () => {
      let finalTimings = {}
      let line = waitFor(' Total time  ')
      if (line === undefined) return undefined
      finalTimings['totalTime'] = {
        'value': line.value.match(floatRegex)[1],
        'info': 'Total Time'
      }
      return finalTimings
    }

    let getMemory = () => {
      let memory = {}
      let line = waitFor('   Maximum')
      if (line === undefined) return undefined
      let m = line.value.match(floatRegex)
      memory['peakMemory'] = {
        'value': m,
        'info': 'Peak memory among tasks (MB)'
      }
      m = waitFor('   Maximum').value.match(floatRegex)
      memory['largestArray'] = {
        'value': m,
        'info': 'Largest tracked array allocation (MB)'
      }
      return memory
    }

    let getExitMode = () => {
      let exitMode = {}
      let line = waitFor(' Have a nice day')
      let value
      if (line === undefined) value = 'no'
      else value = line.value ? 'yes': 'no'
      exitMode['exitMode'] = {
        'value': value,
        'info': 'Calculation exited regularly'
      }
      return exitMode
    }

    let lines = fileText.split('\n')
    let lineIt = linesIterator(lines)

    this.calculationInfo = getCalculationInfo()

    this.inputs.controlIn = getInput(
      /^\s+Parsing control\.in/gm,
      /^\s+Completed first pass over input file control\.in \./gm
    )
    this.getRunTimeChoices()
    this.inputs.geometryIn = getInput(
      /^\s+Parsing geometry\.in/gm,
      /^\s+Completed first pass over input file geometry\.in \./gm
    )
    this.structureIn = getInitialGeometry()
    let currentGeometry = this.structureIn
    this.mulliken = undefined
    this.hirshfeld = undefined


    this.scfLoops = getScfLoops()

    this.finalTimings = getFinalTimings()
    this.memory = getMemory()
    this.exitMode = getExitMode()
  } // normalParser

}
